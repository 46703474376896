import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import paramsService from '../../services/params.service';

const StepSix = () => {
    const dispatch = useDispatch();
    const paddingStyle = { "padding": "10px" }
    const [successMessage, setSuccessMessage] = useState();

    const setActivePage = () => {
        dispatch(
            cartActions.setActiveStep({
                step: "SIX",
            })
        );

    };

    useEffect(() => {
        setActivePage();
        async function successMessage() {
            const params = await paramsService.getAllParams();
            const successMessage = params?.find((item) => item.code === 'SUCCESS_SUBMIT_MESSAGE')?.cvalue;
            setSuccessMessage(successMessage)
        }
        successMessage()
        dispatch(cartActions.orderSubmitted({}));

    }, []);


    return (
        <React.Fragment>

            <div id="stepSix" style={paddingStyle}>
                <h1>Děkujeme</h1>
                <p><h2><br /> {successMessage}</h2></p>
            </div>
        </React.Fragment>
    )
}

export default StepSix