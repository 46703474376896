import React, { useEffect, useState, useCallback } from "react";
import { cartActions } from "../../store/cart-slice";
import { useDispatch, useSelector } from "react-redux";

const StepExtraRequest = (data) => {
  const [ownTextStatus, setOwnTextStatus] = useState(false);

  const cart = useSelector((state) => state.cart);
  const sideType = data.sideType;
  const specialniUmisteni = cart.basket[sideType] !== undefined ? cart.basket[sideType].specialniUmisteni : false;
  const ownTextStyle = { width: "565px", height: "150px" };
  const ownTextToggleStatus = cart.basket[sideType].ownTextToggle;
  const ownText = cart.basket[sideType].ownText;

  const dispatch = useDispatch();

  const onA2ClickHandler = (event) => {
    const value = event.target.value;
    dispatch(
      cartActions.addA2Option({
        value,
        sideType,
      })
    );
    event.stopPropagation();
  };

  const onSpecialPlacementClickHandler = (event) => {
    const value = event.target.value;
    dispatch(
      cartActions.addSpecialPlacement({
        value,
        sideType,
      })
    );
    event.stopPropagation();
  };

  const fileUploadButton = () => {
    document.getElementById("fileButton").click();
    document.getElementById("fileButton").onchange = () => { };
  };

  const saveObrazek = (file, url, fileType) => {
    dispatch(
      cartActions.setVlastniObrazek({
        file,
        url,
        sideType,
        fileType
      })
    );
  };
  const onFileChange = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    const fileType = file.type

    reader.onloadend = () => saveObrazek(reader.result, window.URL.createObjectURL(file), fileType);
    reader.readAsDataURL(file);
  };

  const onDeleteOwnImageClickHandler = (event) => {
    event.preventDefault();
    dispatch(
      cartActions.deleteVlastniObrazek({
        event,
        sideType,
      })
    );
  };

  const toggleOwnTextHandler = (event) => {
    dispatch(
      cartActions.toggleOwnText({
        checked: event.target.checked,
        sideType: sideType,
      })
    );
    setOwnTextStatus(event.target.checked);
  };

  const onOwnTextChangeHandler = (event) => {
    const value = event.target.value;
    dispatch(
      cartActions.setOwnText({
        ownText: value,
        sideType: sideType,
      })
    );
  };

  useEffect(() => {
    setOwnTextStatus(ownTextToggleStatus);
  }, [ownTextToggleStatus, ownTextStatus]);
  return (
    <div className="sliderInfo">
      {1 == 2 &&<>
      <div className="radioInputs clearfix">
        <span>Mám zájem o speciální umístění</span>
        <label>
          <input type="radio" name="special" value="true" checked={specialniUmisteni === true} onChange={onSpecialPlacementClickHandler} /> Ano
        </label>
        <label>
          <input type="radio" name="special" value="false" checked={specialniUmisteni === false} onChange={onSpecialPlacementClickHandler} /> Ne
        </label>
      </div>
       <div className="radioInputs clearfix">
        <span>Velikost A2 : příplatek 25Kč (nelze pro vybraný typ textilu)</span>
        <label>
          <input type="radio" value="true" name="a2print" checked={cart.basket[sideType][sideType + "VelikostA2"] === true} onChange={onA2ClickHandler} /> Ano
        </label>
        <label>
          <input type="radio" value="false" name="a2print" checked={cart.basket[sideType][sideType + "VelikostA2"] === false} onChange={onA2ClickHandler} /> Ne
        </label>
      </div>
      <p>Váš požadavek ohledně speciálního umístění prosím napište do kolonky poznámka<br />(Např. zmenšené na prso, nad kapsu atd.)</p>
      </>}
      {cart.basket[sideType].vlastniObrazekSet && (
        <p id="own_pic_area">
          <strong>Vlastní obrázek:</strong>
          <br />
          {cart.basket[sideType].vlastniObrazekfileType.startsWith("image") && (
            <img id="own_pic" alt="" src={cart.basket[sideType].vlastniObrazek} width="150" />
          )}
          <>
            {cart.basket[sideType].vlastniObrazekfileType === 'application/pdf' && (
              <a href={cart.basket[sideType].vlastniObrazek} download="file.pdf"><img id="own_pic" alt="" src="images/33-333606_pdf-download-icon-png-transparent-png.png" width="30" /></a>
            )}
          </>
          <>
            {cart.basket[sideType].vlastniObrazekfileType !== 'application/pdf' && !cart.basket[sideType].vlastniObrazekfileType.startsWith("image") && (
              <a href={cart.basket[sideType].vlastniObrazek} download="file.pdf"><img id="own_pic" alt="" src="images/docx_icon.svg.png" width="30" /></a>
            )}
          </>
        </p>
       
      )}

      <p>
        Nahrát nový vlastní obrázek
        <input id="fileButton" type="file" hidden onChange={onFileChange} accept='.pdf,.png,.jpg,.jpeg,.doc,.docx' />
        <button className="button gray" onClick={fileUploadButton}>
          Vybrat
        </button>
        {cart.basket[sideType].vlastniObrazekSet && (
          <button className="button gray" id="delete_own_image_button" onClick={onDeleteOwnImageClickHandler}>
            Smazat
          </button>
        )}
      </p>
      {sideType == 'zadniPotisk' && <>
        <input type="checkbox" onChange={toggleOwnTextHandler} id="ownTextStatus" name="ownTextStatus" value="ownTextStatus" checked={ownTextStatus} />
        <label htmlFor="OwnTextCheck"> Vlastní text</label>
        {ownTextStatus && (
          <div>
            <div id="own_text_div">
              <textarea name="back_own_text" style={ownTextStyle} cols="30" rows="6" id="OwnText" onChange={onOwnTextChangeHandler}>
                {ownText}
              </textarea>
            </div>
          </div>
        )}
      </>
      }
    </div>
  );
};

export default StepExtraRequest;
