import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { API_BASE } from "../../constants";

const PopUp = (props) => {
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();

    const popUpType = cart?.popup?.popupType;
    const popUpLabel = cart?.popup?.popupLabel;
    const popUpId = cart?.popup?.popupId;
    const popUpUrl = cart?.popup?.popupUrl;
    const itemsPerPage = cart?.popup?.popupItemsPerPage;
    const oneImageWidth = cart?.popup?.popupOneImageWidth;

    const [popupData, setPopupData] = useState([]);

    const onClosePopUpHandler = (event) => {
        event.preventDefault();
        dispatch(cartActions.closePopUp());
    };

    const onPopUpItemClickHandler = (event) => {
        const id = event.target.id;
        const closeWindow = true;
        const labelTemp = id.split("|");
        const order = labelTemp[4];
        const ribbonColor = labelTemp[2];

        dispatch(
            cartActions.addPrintItemToTheCart({
                id: id,
                closeWindow: closeWindow,
                order: order,
                itemType: popUpType,
                itemsPerPage: itemsPerPage,
                oneImageWidth: oneImageWidth,
                colorData: cart.data[cart.popup?.popupDataType + "Data"].filter((e) => e.id === ribbonColor),
            })
        );
        event.stopPropagation();
    };

    useEffect(() => {
        try {


            let popupData = [{ kod: "0", id: "0" }];
            if (cart.popup?.popupDataType === "dolniPismo" || cart.popup?.popupDataType === "ribbons") {
                popupData = [];
            }
            popupData = popupData.concat(cart.data[cart.popup?.popupDataType + "Data"]);

            setPopupData(popupData);
        } catch (error) {

        }
    }, [cart?.popup?.displayPopup]);

    return (
        <React.Fragment>
            {cart?.popup?.displayPopup && (
                <div id="popup">
                    <div className="content">
                        <a className="close" onClick={onClosePopUpHandler} href>
                            Zavřít
                        </a>
                        <div className="inner">
                            <h2>{popUpLabel}</h2>
                            <div className="clearfix">
                                {popupData !== undefined &&
                                    popupData.length > 0 &&
                                    popupData.map((oneObrazek, i) => (
                                        <div className="thumb" onClick={onPopUpItemClickHandler} id={`popupdiv|${popUpType}|${oneObrazek?.kod}|${oneObrazek?.id}|${i}`}>
                                            <div className="num" id={`popupdiv2|${popUpType}|${oneObrazek?.kod}|${oneObrazek?.id}|${i}`}>
                                                {oneObrazek?.id !== "0" ? `Kód: ${oneObrazek?.kod}` : "-"}
                                            </div>
                                            {oneObrazek?.id !== "0" && (
                                                <React.Fragment>
                                                    <a href={`#${popUpId}`} rel={`${oneObrazek?.id}`} id={`popuphref|${popUpType}|${oneObrazek?.kod}|${oneObrazek?.id}|${i}`}>
                                                        <img
                                                            src={`${API_BASE}/${popUpUrl}/${oneObrazek?.id}`}
                                                            alt={`${oneObrazek?.kod}`}
                                                            id={`popupimg|${popUpType}|${oneObrazek?.kod}|${oneObrazek?.id}|${i}`}
                                                        />
                                                    </a>
                                                </React.Fragment>
                                            )}
                                            {oneObrazek?.id === "0" && (
                                                <React.Fragment>
                                                    <a href="##" id={`popuphref|${popUpType}|0|0|${i}`}>
                                                        Žádný potisk
                                                    </a>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div className="overlay"></div>
                </div>
            )}
        </React.Fragment>
    );
};

export default PopUp;
