import React, { useEffect, useState, useCallback } from "react";
import { cartActions } from '../../store/cart-slice';
import { useDispatch, useSelector } from 'react-redux';
import PrintItem from '../UI/PrintItem';
import SeznamJmen from './SeznamJmen';
import StepExtraRequest from '../UI/StepExtraRequest';
import { useNavigate } from "react-router-dom";
import seznamJmenService from "../../services/seznam-jmen.service";
import dolniPismoService from '../../services/dolni-pismo.service';


const StepThree = (props) => {
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    const cartDolniPismoData = cart.data.dolniPismoData;
    const cartTvarSeznamuJmenData = cart.data.tvarSeznamuJmenData;
    const [isValidStep, setIsValidStep] = useState(false);
    let history = useNavigate();

    const getSeznamJmenData = useCallback(async () => {

        if (cartTvarSeznamuJmenData === undefined || cartTvarSeznamuJmenData.length === 0) {
            const tvarSeznamuJmenData = await seznamJmenService.getAllItems();
            dispatch(
                cartActions.setData({
                    type: 'SEZNAM_JMEN',
                    data: tvarSeznamuJmenData
                })
            );
        }
    }, [cartTvarSeznamuJmenData, dispatch])

    const getDolniPismoData = useCallback(async () => {

        if (cartDolniPismoData === undefined || cartDolniPismoData.length === 0) {
            const dolniPismoData = await dolniPismoService.getAllItems();
            dispatch(
                cartActions.setData({
                    type: 'DOLNI_PISMO',
                    data: dolniPismoData
                })
            );
        }
    }, [cartDolniPismoData, dispatch])

    const setActivePage = () => {
        dispatch(
            cartActions.setActiveStep({
                step: 'THREE'
            })
        );
        if (!cart.stepThreeValid && !cart.stepThreeFirstLoad) {
            dispatch(
                cartActions.setWarning({
                    message: "Nevybrali jste druh písma pro potisk!",
                })
            );

        }
    }

    const previousStepNotComplete = () => {
        const isIncomplete = !cart.stepTwoValid
        setIsValidStep(!isIncomplete)
        return isIncomplete
    }
    
    useEffect(() => {
        if (previousStepNotComplete()) {
            history('/stepTwo')
            return
        }
        getSeznamJmenData();
        getDolniPismoData();
        setActivePage();
    }, [getSeznamJmenData, getDolniPismoData, previousStepNotComplete, setActivePage]);

    return (
        isValidStep && <React.Fragment >

            <PrintItem
                printItemIdLabel="tvar_seznamu_jmen"
                printItemId={1}
                sliderId={31}
                itemList={cartTvarSeznamuJmenData}
                itemStripLabel="Tvar Seznamu Jmen"
                itemType='tvarSeznamuJmen'
                dataType='tvarSeznamuJmen'
                endPoint={'seznam-jmen'}
                itemsPerPage={4}
                oneImageWidth={142} />

            <StepExtraRequest sideType={'zadniPotisk'} />


            <PrintItem
                printItemIdLabel="dolni_pismo"
                printItemId={1}
                sliderId={32}
                itemList={cartDolniPismoData}
                itemStripLabel="Druh písma"
                itemType='dolniPismo'
                dataType='dolniPismo'
                endPoint={'dolni-pismo'}
                itemsPerPage={3}
                oneImageWidth={190}
                svg={true}
                noPrint="false"
            />
            <div className="sliderInfo">
                <p>
                    Druh písma se vztahuje k přednímu i zadnímu potisku.
                </p>
            </div>

            <SeznamJmen></SeznamJmen>

        </React.Fragment >
    )
}

export default StepThree