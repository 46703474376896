/* eslint-disable import/no-anonymous-default-export */

// const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const getParamNValue = (data, code) => {
  const retValue = data?.paramsData?.find((item) => item.code === code)?.nvalue;
  return retValue
}

const getParamCValue = (data, code) => {
  const retValue = data?.paramsData?.find((item) => item.code === code)?.cvalue;
  return retValue
}

// async function waitedDelay(ms) {
//   await delay(ms);
// }

const validateStepTwo = (basket) => {
  const potisk = basket.predniPotisk;
  let stepTwoComplete = true;
  if (typeof potisk.barvaPotisku === "undefined") {
    stepTwoComplete = stepTwoComplete && false;
  }
  return stepTwoComplete;
};

const validateStepThree = (basket) => {
  const potisk = basket.predniPotisk;
  let stepThreeComplete = true;
  if (typeof potisk.dolniPismo === "undefined") {
    stepThreeComplete = stepThreeComplete && false;
  }
  return stepThreeComplete;
};

const validateStepFive = (basket) => {
  const potisk = basket.predniPotisk;
  let stepFourComplete = true;
  stepFourComplete = stepFourComplete && validateRibbons(basket)
  stepFourComplete = stepFourComplete || basket.lists.chosen
  if (basket?.items?.length > 0) {
    if (typeof potisk.dolniPismo === "undefined") {
      stepFourComplete = stepFourComplete && false;
    }
    if (typeof potisk.barvaPotisku === "undefined") {
      stepFourComplete = stepFourComplete && false;
    }
  }

  if (basket?.items?.length === 0 && !basket.ribbons.chosen && !basket.lists.chosen) {
    stepFourComplete = stepFourComplete && false;
  }
  return stepFourComplete;
};

const validateRibbons = (basket) => {
  let isValid = true
  if (basket.ribbons.chosen) {
    isValid = isValid && (basket?.ribbons?.pismo !== undefined ? true : false)

    isValid = isValid && (basket?.ribbons?.printColorCode !== undefined ? true : false)
  }
  return isValid

}

const getBasketPrice = (basket, data) => {
  const postovne = getParamNValue(data, 'POSTOVNE')
  const priplatky = getPriplatky(basket, data);
  let totalQuantity = 0;
  let totalPrice = 0;

  const priplatkyCelkem = priplatky?.priplatkyCelkem;
  const priplatkyRukavCelkem = priplatky?.priplatkyRukavCelkem;
  basket?.items.forEach(function (element) {
    const mappedNamesCount = basket?.students?.mappedNames[element.id]?.length > 0 ? basket?.student?.mappedNames[element.id]?.length : 0;
    const noSleevePrintCount = element.quantity - mappedNamesCount;
    const priceWithoutSleevePrint = element.price + priplatkyCelkem;
    const priceWithSleevePrint = priceWithoutSleevePrint + priplatkyRukavCelkem;

    totalQuantity += parseInt(element.quantity);

    let pricePerPiece = element.price;
    pricePerPiece += priplatky.priplatkyCelkem;

    const totalPriceNoSleevePrint = noSleevePrintCount * priceWithoutSleevePrint;
    const totalPriceSleevePrint = mappedNamesCount * priceWithSleevePrint;
    const totalPricePerPiece = totalPriceNoSleevePrint + totalPriceSleevePrint;

    totalPrice += totalPricePerPiece;
  });


  const ribbons = getRibbonsPrice(basket, data);
  const lists = getListsPrice(basket, data);
  totalPrice += ribbons.ribbonsPrice;
  totalPrice += lists.listsPrice;

  const itemPriceDetail = getItemDetails(basket, data)

  if (totalPrice > 0)
    totalPrice += postovne

  const retData = { totalPrice: totalPrice, totalQuantity: totalQuantity, ribbons: ribbons, lists: lists, priplatky: priplatky, postovne: postovne, itemPriceDetail: itemPriceDetail }
  return retData;
};

const getPriplatky = (basket, data) => {
  const priplatky = [];
  let priplatkyCelkem = 0;
  let priplatkyRukavCelkem = 0;
  const sleevePrintPrice = getParamNValue(data, 'NICKNAMES')

  if (basket?.predniPotisk?.predniPotiskVelikostA2) {
    const priplatekValue = getParamNValue(data, 'A2FRONT')
    priplatky.push({ text: `Příplatek za velikost A2 přední potisk: ${priplatekValue},00 Kč / ks`, value: parseInt(priplatekValue), sleeve: false, order: 20 });
    priplatkyCelkem += priplatekValue;
  }
  if (basket?.zadniPotisk?.zadniPotiskVelikostA2) {
    const priplatekValue = getParamNValue(data, 'A2BACK')
    priplatky.push({ text: `Příplatek za velikost A2 zadní potisk: ${priplatekValue},00 Kč / ks`, value: parseInt(priplatekValue), sleeve: false, order: 40 });
    priplatkyCelkem += priplatekValue;
  }
  // additional costs for sleeve print
  if (basket?.sleeves?.leftSleeveNickNames) {
    priplatky.push({ text: `Příplatek za přezdívky - levý rukáv: ${sleevePrintPrice},00 Kč / ks`, value: sleevePrintPrice, sleeve: true, order: 50 });
    priplatkyRukavCelkem += sleevePrintPrice;
  }
  if (basket?.sleeves?.rightSleeveNickNames) {
    priplatky.push({ text: `Příplatek za přezdívky - pravý rukáv: ${sleevePrintPrice},00 Kč / ks`, value: sleevePrintPrice, sleeve: true, order: 70 });
    priplatkyRukavCelkem += sleevePrintPrice;
  }

  if (basket?.sleeves?.leftSleeveText) {
    priplatky.push({ text: `Příplatek za potisk - levý rukáv: ${sleevePrintPrice},00 Kč / ks`, value: sleevePrintPrice, sleeve: true, order: 90 });
    priplatkyRukavCelkem += 60;
  }

  if (basket?.sleeves?.rightSleeveText) {
    priplatky.push({ text: `Příplatek za potisk - pravý rukáv: ${sleevePrintPrice},00 Kč / ks`, value: sleevePrintPrice, sleeve: true, order: 100 });
    priplatkyRukavCelkem += 60;
  }
 

  const barvaId = basket?.predniPotisk?.barvaPotisku?.split("|")[2];
  const foundBarvaCena = data?.barvaPotiskuData.find((barva) => parseInt(barva.id) === parseInt(barvaId));
  if (foundBarvaCena !== undefined) {
    let barvaCenaFront = foundBarvaCena?.cena;
    let barvaCenaBack = foundBarvaCena?.cena;

    // additional costs for sleeve print in extra color
    if (basket.sleeves.leftSleeveNickNames) {
      priplatky.push({ text: `Příplatek za přezdívky - levý rukáv (příplatková barva): ${barvaCenaFront},00 Kč / ks`, value: barvaCenaFront, sleeve: true, order: 51 });
      priplatkyRukavCelkem += barvaCenaFront;
    }
    if (basket.sleeves.rightSleeveNickNames) {
      priplatky.push({ text: `Příplatek za přezdívky - pravý rukáv (příplatková barva): ${barvaCenaFront},00 Kč / ks`, value: barvaCenaFront, sleeve: true, order: 71 });
      priplatkyRukavCelkem += barvaCenaFront;
    }


    if (basket?.sleeves?.leftSleeveText) {
      priplatky.push({ text: `Příplatek za potisk - levý rukáv: (příplatková barva): ${barvaCenaFront},00 Kč / ks`, value: barvaCenaFront, sleeve: true, order: 91 });
      priplatkyRukavCelkem += barvaCenaFront;
    }
  
    if (basket?.sleeves?.rightSleeveText) {
      priplatky.push({ text: `Příplatek za potisk - pravý rukáv: (příplatková barva): ${barvaCenaFront},00 Kč / ks`, value: barvaCenaFront, sleeve: true, order: 101 });
      priplatkyRukavCelkem += barvaCenaFront;
    }

    let a2ClauseFront = "";
    let a2ClauseBack = "";
    if (basket.predniPotisk.predniPotiskVelikostA2) {
      barvaCenaFront = foundBarvaCena?.cena_trickoa2;
      a2ClauseFront = "A2";
      priplatkyCelkem += barvaCenaFront;
    } else {
      priplatkyCelkem += barvaCenaFront;
    }
    if (basket.zadniPotisk.zadniPotiskVelikostA2) {
      barvaCenaBack = foundBarvaCena?.cena_trickoa2;
      a2ClauseBack = "A2";
      priplatkyCelkem += barvaCenaBack;
    }
    if (basket?.zadniPotisk?.tvarSeznamuJmen !== undefined && basket?.zadniPotisk?.tvarSeznamuJmen !== "tvarSeznamuJmen|0|0") {
      priplatkyCelkem += barvaCenaBack;
      priplatky.push({ text: `Příplatek za barvu zadního potisku ${a2ClauseBack} : ${barvaCenaBack},00 Kč / ks`, value: parseInt(barvaCenaBack), sleeve: false, order: 30 });
    }
    priplatky.push({ text: `Příplatek za barvu předního potisku ${a2ClauseFront} : ${barvaCenaFront},00 Kč / ks`, value: parseInt(barvaCenaFront), sleeve: false, order: 10 });
  }

  const filteredPriplatky = priplatky.filter((item) => item.value > 0);

  const priplatkyNotSleeve = filteredPriplatky?.filter((priplatek) => priplatek.sleeve === false)?.sort((a, b) => (a.order > b.order ? 1 : -1));
  const priplatkyNotSleeveExist = priplatkyNotSleeve?.length > 0;

  const priplatkySleeve = filteredPriplatky?.filter((priplatek) => priplatek.sleeve === true)?.sort((a, b) => (a.order > b.order ? 1 : -1));
  const priplatkySleeveExist = priplatkySleeve?.length > 0;

  const priplatkyExist = filteredPriplatky?.length > 0;

  return {
    priplatky: filteredPriplatky,
    priplatkyCelkem: priplatkyCelkem,
    priplatkyRukavCelkem: priplatkyRukavCelkem,
    priplatkyNotSleeve: priplatkyNotSleeve,
    priplatkyNotSleeveExist: priplatkyNotSleeveExist,
    priplatkySleeve: priplatkySleeve,
    priplatkySleeveExist: priplatkySleeveExist,
    priplatkyExist: priplatkyExist,
  };
};

const getListsPrice = (basket, data) => {
  let listsPrice = 0;
  let listsExist = false;
  let listsQuantity = 0;
  let listsItemPrice = getParamNValue(data, 'LIST_NO_TEXTIL')
  if (basket?.lists?.chosen) {
    const itemsCount = basket?.items?.length;
    if (itemsCount > 0) {
      listsItemPrice = getParamNValue(data, 'LIST_TEXTIL')
    }
    listsPrice = basket.lists.quantity * listsItemPrice;
    listsQuantity = basket.lists.quantity;
    listsExist = true;
  }
  return { listsPrice: listsPrice, listsQuantity: listsQuantity, listsExist: listsExist, listsItemPrice: listsItemPrice };
};

const getRibbonsPrice = (basket, data) => {
  try {
    let ribbonsPrice = 0;
    let ribbonsExists = false;
    let ribbonsQuantity = 0;
    let ribbonItemPrice = getParamNValue(data, 'RIBBON_NO_TEXTIL')
    let ribbonsNickPrice = 0;
    let ribbonsNickItemPrice = getParamNValue(data, 'RIBBON_NICKNAMES')
    let nicks = Object.values(basket?.ribbons?.nicks)
    const validItems = nicks.filter((e) => e?.length > 0);
    nicks = validItems;
    let ribbonsNicksSelected = basket?.ribbons?.addNicks
    let ribbonsNicksSize = (ribbonsNicksSelected === true) ? nicks?.length : 0;
    const ribbonPrintColorPrice = basket?.ribbons?.printColorPrice

    if (basket?.ribbons?.chosen) {
      const itemsCount = basket?.items?.length;
      if (itemsCount > 0) {
        ribbonItemPrice = getParamNValue(data, 'RIBBON_TEXTIL')
      }
      ribbonsPrice = parseInt(basket.ribbons.quantity - ribbonsNicksSize) * (parseInt(ribbonItemPrice) + parseInt(ribbonPrintColorPrice));
      ribbonsNickPrice = parseInt(ribbonsNicksSize) * (parseInt(ribbonItemPrice) + parseInt(ribbonPrintColorPrice) + parseInt(ribbonsNickItemPrice));
      ribbonsQuantity = basket.ribbons.quantity;
      ribbonsExists = true;
    }
    const retData = { ribbonsPrice: ribbonsPrice + ribbonsNickPrice, ribbonsQuantity: ribbonsQuantity, ribbonsExists: ribbonsExists, ribbonItemPrice: ribbonItemPrice, ribbonPrintColorPrice: ribbonPrintColorPrice, ribbonsNicks: ribbonsNicksSelected, ribbonsNicksSize: ribbonsNicksSize };
    return retData
  } catch (error) {
    return {}
  }
};

const getItemDetails = (basket, data) => {
  let itemDetails = []
  const priplatky = getPriplatky(basket, data)
  basket?.items?.forEach((oneItem) => {

    const priplatkyExist = priplatky?.priplatkyExist;
    const priplatkyCelkem = priplatky?.priplatkyCelkem;
    const priplatkyRukavCelkem = priplatky?.priplatkyRukavCelkem;

    const priplatkyNotSleeve = priplatky?.priplatkyNotSleeve;
    const priplatkyNotSleeveExist = priplatky?.priplatkyNotSleeveExist;

    const priplatkySleeve = priplatky?.priplatkySleeve;
    const priplatkySleeveExist = priplatky?.priplatkySleeveExist;

    const mappedNamesCount = basket?.students?.mappedNames[oneItem.id]?.length > 0 ? basket?.students?.mappedNames[oneItem.id]?.length : 0;
    const noSleevePrintCount = oneItem.quantity - mappedNamesCount;
    const priceWithoutSleevePrint = oneItem.price + priplatkyCelkem;
    const priceWithSleevePrint = priceWithoutSleevePrint + priplatkyRukavCelkem;
    const totalPriceNoSleevePrint = noSleevePrintCount * priceWithoutSleevePrint;
    const totalPriceSleevePrint = mappedNamesCount * priceWithSleevePrint;
    const totalPrice = totalPriceNoSleevePrint + totalPriceSleevePrint;

    const ret = {
      itemId: oneItem.id,
      mappedNamesCount: mappedNamesCount,
      noSleevePrintCount: noSleevePrintCount,
      priceWithoutSleevePrint: priceWithoutSleevePrint,
      priceWithSleevePrint: priceWithSleevePrint,
      totalPriceNoSleevePrint: totalPriceNoSleevePrint,
      totalPriceSleevePrint: totalPriceSleevePrint,
      totalPrice: totalPrice,
      totalCount: oneItem.quantity
    }
    itemDetails.push(ret)
  })
  return itemDetails
  //         existingItem = state.basket.items.find((item) => item.id === newItem.id);

}

export default {
  validateStepTwo,
  validateStepThree,
  getBasketPrice,
  getPriplatky,
  getRibbonsPrice,
  getListsPrice,
  getParamCValue,
  getParamNValue,
  validateStepFive,
  validateRibbons,
  getItemDetails
};
