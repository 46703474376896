import React, { useEffect, useState } from "react";
import { API_BASE } from "../../constants";

const StepFiveShirtPreview = (props) => {

    const [urlDetailsFront, setUrlDetailsFront] = useState("");
    const [urlDetailsBack, setUrlDetailsBack] = useState("");

    const thumbStyle = { display: "inline-block", overflow: "hidden", width: "24.5%", height: "320px", cursor: "default" };
    const marginLeft = { marginLeft: "0px", marginTop: "20px"};

    const basket = props.basket
    const oneItem = props.textil

    useEffect(() => {
        let urlDetailsFront = "";
        let urlDetailsBack = "";

        if ("obrazekPotisku" in basket?.predniPotisk && basket?.predniPotisk?.obrazekPotisku !== "obrazekPotisku|0|0") {
            const obrazek = basket?.predniPotisk?.obrazekPotisku.split("|");
            urlDetailsFront += "/o-" + obrazek[2];
        }

        if ("horniNapis" in basket?.predniPotisk && basket?.predniPotisk?.horniNapis !== "horniNapis|0|0") {
            const potisk = basket?.predniPotisk?.horniNapis.split("|");
            urlDetailsFront += "/p-" + potisk[2];
        }

        const colorId = basket?.predniPotisk?.barvaPotisku?.split("|");
        if (colorId !== undefined) {
            urlDetailsFront += "/c-" + colorId[2];
            urlDetailsBack += "/c-" + colorId[2];
        }

        if ("tvarSeznamuJmen" in basket?.zadniPotisk && basket?.zadniPotisk?.tvarSeznamuJmen !== "tvarSeznamuJmen|0|0") {
            const obrazek = basket?.zadniPotisk?.tvarSeznamuJmen.split("|");
            urlDetailsBack += "/j-" + obrazek[2];
        }
        setUrlDetailsFront(urlDetailsFront);
        setUrlDetailsBack(urlDetailsBack);
    });

    return (
        <React.Fragment>
            <div className="thumb" style={thumbStyle}>
                <img
                    id={`shirtPreview/front/s-${oneItem.textil}/sc-${oneItem.colorId}${urlDetailsFront}`}
                    style={marginLeft}
                    src={`${API_BASE}/shirtPreview/front/s-${oneItem.textil}/sc-${oneItem.colorId}${urlDetailsFront}`}
                    alt=""
                />
            </div>
            <div className="thumb" style={thumbStyle}>
                <img
                    id={`shirtPreview/back/s-${oneItem.textil}/sc-${oneItem.colorId}${urlDetailsBack}`}
                    style={marginLeft}
                    src={`${API_BASE}/shirtPreview/back/s-${oneItem.textil}/sc-${oneItem.colorId}${urlDetailsBack}`}
                    alt=""
                />
            </div>
        </React.Fragment>
    )


}


export default StepFiveShirtPreview
