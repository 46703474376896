import React, { useEffect, useState, useCallback } from "react";
import { cartActions } from "../../store/cart-slice";
import { useDispatch, useSelector } from "react-redux";
import PrintItemDetail from "./PrintItemDetail";

const PrintItem = (data) => {
  const itemActivePage = useSelector((state) => state.cart[data.itemType + "ActivePage"]);
  const itemChosenPage = useSelector((state) => state.cart[data.itemType + "ChosenPage"]);
  const itemChosenPageShift = useSelector((state) => state.cart[data.itemType + "ChosenPageShift"]);
  const itemShift = useSelector((state) => state.cart[data.itemType + "Shift"]);
  const itemWidth = useSelector((state) => state.cart[data.itemType + "Width"]);
  const oneImageWidth = data.oneImageWidth;
  const dispatch = useDispatch();

  const [contentStyle, setContentStyle] = useState({ width: "100000px", left: "0px" });
  const [itemMaxPages, setItemMaxPages] = useState([]);

  const pageInit = useCallback(async () => {
    const localItemWidth = itemWidth === undefined || itemWidth < 1000 ? 100000 : itemWidth;
    let pageShift = itemShift;

    if (itemChosenPage > 0) pageShift = itemChosenPageShift;

    const localContentStyle = {
      width: localItemWidth + "px",
      left: pageShift + "px",
    };
    setContentStyle(localContentStyle);
    const itemListLength = data.itemList !== undefined ? data.itemList.length + 1 : 100000;
    const itemMaxPage = Math.ceil(itemListLength / data.itemsPerPage) 
    setItemMaxPages(itemMaxPage);
  }, [data.itemList, data.itemsPerPage, itemChosenPage, itemChosenPageShift, itemShift, itemWidth]);

  const arrowNext = () => {
    const localItemWidth = itemWidth === undefined || itemWidth < 1000 ? 100000 : itemWidth;
    let page = 0;
    if (itemActivePage !== undefined) page = itemActivePage + 1;
    if (page >= itemMaxPages) page = 0;
    let napisShift = page * -oneImageWidth * data.itemsPerPage;
    setContentStyle({
      width: localItemWidth + "px",
      left: napisShift + "px",
    });

    dispatch(
      cartActions.setItemTypeActivePage({
        data: {
          page: page,
          itemType: data.itemType,
          dataType: data.dataType,
          itemsPerPage: data.itemsPerPage,
          oneImageWidth: data.oneImageWidth,
        },
      })
    );
  };

  const arrowPrev = () => {
    let page = 0;
    if (itemActivePage !== undefined) page = itemActivePage - 1;

    if (page < 0) page = itemMaxPages - 1;

    let napisShift = page * -oneImageWidth * data.itemsPerPage;
    setContentStyle({
      width: (itemWidth === 0 ? 100000 : itemWidth) + "px",
      left: napisShift + "px",
    });
    dispatch(
      cartActions.setItemTypeActivePage({
        data: {
          page: page,
          itemType: data.itemType,
          dataType: data.dataType,
          itemsPerPage: data.itemsPerPage,
          oneImageWidth: data.oneImageWidth,
        },
      })
    );
  };

  const onShowAllPrintItemsClickHandler = (event) => {
    dispatch(
      cartActions.openPopUp({
        popupType: data.itemType,
        popupDataType: data.dataType,
        popupLabel: data.itemStripLabel,
        popupId: data.printItemIdLabel,
        popupUrl: data.endPoint,
        popupItemsPerPage: data.itemsPerPage,
        popupOneImageWidth: data.oneImageWidth,
      })
    );
  };

  useEffect(() => {
    pageInit();
  }, [itemChosenPage]);

  return (
    <React.Fragment>
      <div className="relative">
        <h2 className={"strip n" + data.printItemId}>{data.itemStripLabel}</h2>
        <a href="##" className="showAll" rel={data.itemType} onClick={onShowAllPrintItemsClickHandler}>
          Zobrazit všechny <span></span>
        </a>
      </div>

      <div id={"slider" + data.sliderId} className="slider clearfix">
        <button className="arrow prev" onClick={arrowPrev}>
          Předchozí
        </button>
        <button className="arrow next" onClick={arrowNext}>
          Další
        </button>
        <div className="wrapper">
          <div className="content" id={data.printItemIdLabel} style={contentStyle}>
            {data.noPrint !== "false" && <PrintItemDetail kod={"0"} id={"0"} key={data.itemType + "|0|0"} itemType={data.itemType} />}
            {(data.itemList !== undefined && data.itemList.length > 0) &&
              data?.itemList?.map((oneNapis) => (
                <PrintItemDetail
                  kod={oneNapis.kod}
                  id={oneNapis.id}
                  key={data.itemType + "|" + oneNapis.kod + "|" + oneNapis.id}
                  endPoint={data.endPoint}
                  itemType={data.itemType}
                  dataType={data.dataType}
                  svg={data.svg}
                  itemsPerPage={data.itemsPerPage}
                  oneImageWidth={data.oneImageWidth}
                  colorData={oneNapis}
                />
              ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PrintItem;
