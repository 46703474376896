import React, { useEffect, useCallback, useState } from "react";
import SeznamJmen from "../StepThree/SeznamJmen";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import genericService from '../../services/generic.service'
import paramsService from '../../services/params.service';
import { API_BASE } from "../../constants";

const StepLists = () => {
  const basket = useSelector((state) => state.cart.basket);
  const data = useSelector((state) => state.cart.data);
  const width142 = { width: "142" };
  const width570 = { width: "570px" };
  const listTextStyle = { width: "565px", height: "150px" };
  const marginTop = { marginTop: "10px" };
  const itemType = "lists";
  const textItemType = "listText";
  const dataType = "lists";
  const itemsPerPage = 1;
  const oneImageWidth = 142;
  const listCartData = basket.lists;
  const kod = "04";
  const id = 4;
  const itemId = itemType + "|" + kod + "|" + id;
  const itemTextId = textItemType + "|" + kod + "|" + id;
  const dispatch = useDispatch();
  const imgId = "img|" + itemId;
  const [ownTextStatus, setOwnTextStatus] = useState(false);
  const ownText = basket?.lists?.ownText;
  const [listsNoTextil, setListsNoTextil] = useState(0)
  const [listsTextil, setListsTextil] = useState(0)

  const setActivePage = () => {
    dispatch(
      cartActions.setActiveStep({
        step: "LISTS",
      })
    );
  };

  const isItemActive = (kod) => {
    let status = "";
    const chosenItem = listCartData.listId;
    if (kod === chosenItem) {
      status = "active";
    }
    return status;
  };

  const toggleOwnTextHandler = (event) => {
    dispatch(
      cartActions.toggleListsOwnText({
        checked: event.target.checked,
      })
    );
    setOwnTextStatus(event.target.checked);
  };

  const onOwnTextChangeHandler = (event) => {
    const value = event.target.value;
    dispatch(
      cartActions.setListsOwnText({
        ownText: value,
      })
    );
  };

  const isTextItemActive = () => {
    let status = "";
    const chosenItem = listCartData.listId;
    if (itemId === chosenItem && (!listCartData.ownTextChosen || listCartData.ownTextChosen === undefined)) {
      status = "active";
    }
    return status;
  };

  const onListsItemClickHandler = (event) => {
    const id = event.target.id;
    const listsPrice = genericService.getListsPrice(basket)
    dispatch(
      cartActions.addPrintItemToTheCart({
        id: id,
        itemType: itemType,
        dataType: dataType,
        imagesPerPage: itemsPerPage,
        oneImageWidth: oneImageWidth,
        price: listsPrice.listsItemPrice
      })
    );
    if (isNaN(listCartData.quantity)) {
      dispatch(
        cartActions.setListsCount({
          quantity: 10,
        })
      );
    }
  };

  const onListCountChangeHandler = (event) => {
    let count = parseInt(event.target.value, 0);
    if (isNaN(count)) {
      count = 10;
    }

    dispatch(
      cartActions.setListsCount({
        quantity: count,
      })
    );

    dispatch(cartActions.hideWarning());
  };

  const updateInput = useCallback(async (event) => {
    event.preventDefault();

    const count = parseInt(event.target.value, 0);
    if (count < 10) {
      dispatch(
        cartActions.setListsCount({
          quantity: 10,
        })
      );
    } else {
      dispatch(cartActions.hideWarning());
      dispatch(
        cartActions.setListsCount({
          quantity: count,
        })
      );
    }
  });

  const getParams = useCallback(async () => {
    const paramsData = await paramsService.getAllParams();

    dispatch(
        cartActions.setData({
            type: 'PARAMS',
            data: paramsData,
        })
    );

    setListsTextil(genericService.getParamNValue(data, 'LIST_TEXTIL'))
    setListsNoTextil(genericService.getParamNValue(data, 'LIST_NO_TEXTIL'))

  }, []);

  useEffect(() => {
    setActivePage();
    getParams()
  }, [getParams]);

  return (
    <React.Fragment>
      <div className="relative">
        <h2 className="strip n1">Listina</h2>
      </div>
      <div id="slider1" className="slider clearfix">
        <div className="wrapper">
          <div className="content" style={width142}>
            <div className="item" onClick={onListsItemClickHandler} id={"div|" + itemId}>
              <small>Kód 4</small>
              <a href="#" className={isItemActive(itemId)} rel={`${id}`} id={"href|" + itemId}>
                <img src={`${API_BASE}/listina/getPicture/4/100`} id={imgId} alt={itemId} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="lists_count_div" className="sliderInfo">
        <label htmlFor="listsCount">Počet kusů </label>
        <input
          name="listsCount"
          className="lists_count_input"
          type="number"
          id="listsCount"
          value={listCartData.quantity}
          disabled={listCartData.chosen !== true}
          min="10"
          onChange={onListCountChangeHandler}
          onBlur={updateInput}
        />
        {!listCartData.chosen && (
          <span id="list_select_required_info">
            &nbsp;<strong>Nejprve vyberte typ pamětní listiny!</strong>
          </span>
        )}
        <div style={marginTop}>Pokud si současně objednáte i textil, je cena pamětní listiny {listsTextil}Kč, při objednání pouze listin je cena za kus {listsNoTextil}Kč. Minimální počet je 10 kusů.</div>
      </div>
      {listCartData.chosen && (
        <>
          <div id="list_texts_div">
            <div className="relative">
              <a name="dolni_napis"></a>
              <h2 className="strip n1">Text</h2>
              <input type="hidden" name="lists[text_id]" id="lists_text_id" />
            </div>

            <div id="slider2" className="slider clearfix">
              <div className="wrapper" style={width570}>
                <div className="content" style={width570}>
                  <div className="item" style={width570}>
                    <small>Kód 4</small>
                    <a style={width570} className={isTextItemActive(itemTextId)} rel={`${itemTextId}`} id={"href|" + itemTextId}>
                      <img src={`${API_BASE}/listina/getText/4/560`} alt="3" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div id="own_text" className="sliderInfo">
              <input type="checkbox" onChange={toggleOwnTextHandler} id="ownTextStatus" name="ownTextStatus" value="ownTextStatus" checked={ownTextStatus} />
              <label htmlFor="listsOwnText"> Vlastní text</label>

              {ownTextStatus && (
                <div id="own_text_div">
                  <textarea name="lists_own_text" style={listTextStyle} cols="30" rows="6" id="listsOwnText" onChange={onOwnTextChangeHandler}>
                    {ownText}
                  </textarea>
                </div>
              )}
            </div>
          </div>
          <SeznamJmen />
        </>
      )}
    </React.Fragment>
  );
};

export default StepLists;
