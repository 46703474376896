import React, { useEffect, useState, useCallback } from 'react';
import { cartActions } from '../../store/cart-slice';
import { useDispatch, useSelector } from 'react-redux';
import SchoolInfoItem from './SchoolInfoItem';
import SchoolInfoName from './SchoolInfoName';
const SeznamJmen = () => {
    const dispatch = useDispatch();

    const cart = useSelector((state) => state.cart);
    const defaultNameLinesCount = 30;
    const [nameLinesCount, setNameLinesCount] = useState([]);
    const [listOfName, setListOfNames] = useState([]);
    const [importNamesStatus, setImportNamesStatus] = useState(false);

    const pageInit = useCallback(async () => {
        setNameLinesCount(defaultNameLinesCount < cart.basket.students.names.length || cart.basket.students.names.length === 0 ? defaultNameLinesCount : cart.basket.students.names.length)
    }, [])

    const onAddNamesRowClickHandler = (event) => {
        setNameLinesCount(nameLinesCount + 1)
    }

    const onImportNamesChangeHandler = (event) => {
        var lines = event.target.value.split('\n');
        for (var i = 0; i < lines.length; i++) {
            console.log(lines[i])
            dispatch(
                cartActions.setSchoolNamesData({
                    id: "studentName#" + (i + 1),
                    value: lines[i],
                })
            );
        }

        setListOfNames(event.target.value)



    }

    const toggleImportNamesHandler = (event) => {
        setImportNamesStatus(event.target.checked);

    }

    const ownTextStyle = { width: "93%", height: "150px" };

    useEffect(() => {
        pageInit();
    }, [pageInit]);


    return (
        <React.Fragment>

            <a name="seznam"></a>

            <h2 className="strip n3">Seznam jmen</h2>
            <fieldset className="names">
                <label htmlFor="ImportNamesStatusCheck"> Vložit jména</label>
                <input type="checkbox" onChange={toggleImportNamesHandler} id="ImportNamesStatusCheck" name="ImportNamesStatusCheck" checked={importNamesStatus} />
                {importNamesStatus && (
                    <div>
                        <div id="import_names_div">
                            <textarea name="importNames" className="text" style={ownTextStyle} cols="30" rows="6" id="importNames" onChange={onImportNamesChangeHandler}>
                                {listOfName}
                            </textarea>
                        </div>
                    </div>)}
            </fieldset>
            <fieldset className="names">
                <div className="clearfix">
                    <SchoolInfoItem label="Název školy" id="nazev_skoly" />
                    <SchoolInfoItem label="Roky na škole" id="roky" isMiddle={true} />
                    <SchoolInfoItem label="Třída" id="trida" />
                </div>

                <div className="clearfix">
                    <SchoolInfoItem label="Třídní učitel" id="tridni" />
                    <SchoolInfoItem label="Ředitel" id="reditel" isMiddle={true} />
                    <SchoolInfoItem label="Město - Obec" id="skola-mesto" />
                </div>
                <div className="clearfix">
                    <SchoolInfoItem label="Asistent/Asistentka" id="asistent" />
                </div>
                <div className="rows">
                    <div className="clearfix">
                        <label className="name">Jméno Příjmení</label>
                    </div>
                    {[...Array(Math.max(nameLinesCount, 30))].map((elementInArray, i) => (
                        <SchoolInfoName key={`SchoolInfoName${i}`} id={i + 1} />
                    ))}

                </div>
                <div className="row clearfix" onClick={onAddNamesRowClickHandler}>
                    <div className="num bold" >+</div>
                    <div className="nameInput">
                        Přidat další pole
                    </div>
                    <div className="nickInput">
                        &nbsp;
                    </div>
                    <div className="remove">&nbsp;</div>
                </div>


            </fieldset>
        </React.Fragment>
    )
}

export default SeznamJmen