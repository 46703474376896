import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import ShirtThumb from "./ShirtThumb";
import ShirtThumbDetail from "./ShirtThumbDetail";
import ListsThumb from "./ListsThumb";
import RibbonsThumb from "./RibbonsThumb";
import ListsThumbDetail from "./ListsThumbDetail";
import RibbonsThumbDetail from "./RibbonsThumbDetail";
import ShirtPreview from "./ShirtPreview";
import genericService from "../../services/generic.service";
import { API_BASE } from "../../constants";

const SideDetail = () => {
  const basket = useSelector((state) => state.cart?.basket);
  const predniPotisk = basket !== undefined ? basket?.predniPotisk : undefined;
  const horniNapis = basket !== undefined ? basket?.predniPotisk?.horniNapis : undefined;
  const barvaPotisku = basket !== undefined ? basket?.predniPotisk?.barvaPotisku : 13;
  const obrazekPotisku = basket !== undefined ? basket?.predniPotisk?.obrazekPotisku : undefined;
  const textil = basket !== undefined ? basket.items : [];
  const data = useSelector((state) => state.cart.data);
  const activeThumb = useSelector((state) => state.cart.activeThumb);
  const chosenSeznamJmen = basket?.zadniPotisk?.tvarSeznamuJmen !== undefined ? basket?.zadniPotisk?.tvarSeznamuJmen : undefined;
  const activeStep = useSelector((state) => state.cart?.activeStep);
  const listsExist = basket?.lists?.chosen;
  const ribbonsExist = basket?.ribbons?.chosen;
  const listThumbChosen = basket?.lists?.listThumbChosen;
  const ribbonsThumbChosen = basket?.ribbons?.ribbonsThumbChosen;
  const [thumbData, setThumbData] = useState([]);
  const [shirtId, setShirtId] = useState([]);
  const [shirtColorId, setShirtColorId] = useState([]);
  const [potiskId, setPotiskId] = useState([]);
  const [obrazekId, setObrazekId] = useState([]);
  const [colorId, setColorId] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const basketPrice = genericService.getBasketPrice(basket, data);

  const getCodes = useCallback(async () => {
    const thumbSide = activeStep === "THREE" ? "back" : "front";
    let urlDetails = "";
    if ((activeThumb !== undefined && textil !== undefined) || textil.length > 0) {
      let thumbDataTemp = [];
      let tmpArray = [];
      for (const oneTextilKey of Object.keys(textil)) {
        const oneTextil = textil[oneTextilKey];
        const textilDetail = oneTextil.id.split("#");
        if (textilDetail !== undefined) {
          const detailValue = {
            textilId: textilDetail[0],
            barvaId: textilDetail[1],
          };
          if (tmpArray.indexOf(textilDetail[0] + "#" + textilDetail[1]) < 0 && oneTextil.quantity > 0) {
            thumbDataTemp.push(detailValue);
            tmpArray.push(textilDetail[0] + "#" + textilDetail[1]);
          }
        }
      }
      if (chosenSeznamJmen !== undefined && thumbSide === "back") {
        const itemCodeArray = chosenSeznamJmen.split("|");

        const itemCodeId = itemCodeArray[2];
        urlDetails += "/j-" + itemCodeId;
      }

      setThumbData(thumbDataTemp);
      let activeTh = activeThumb;
      if (activeThumb === undefined) {
        activeTh = tmpArray[0];
      }

      const textilId = activeTh.split("#");
      setShirtId(textilId[1]);
      setShirtColorId(textilId[2]);
      if (predniPotisk !== undefined ) {
        // && horniNapis !== undefined && obrazekPotisku !== undefined
        let color = "barvaPotisku|13|13".split("|");
        if (barvaPotisku !== undefined) {
          color = (barvaPotisku + "").split("|");
        }

        const potisk = (horniNapis + "").split("|");
        if (potisk[2] !== undefined && potisk[2] !== "0" && thumbSide === "front") {
          urlDetails += "/p-" + potisk[2];
        }

        const obrazek = (obrazekPotisku + "").split("|");

        setObrazekId(obrazek[2]);
        if (obrazek[2] !== undefined && obrazek[2] !== "0" && thumbSide === "front") {
          urlDetails += "/o-" + obrazek[2];
        }

        if (colorId !== undefined) {
          urlDetails += "/c-" + colorId;
        }
        setColorId(color[2]);
        const imageUrl = `${API_BASE}/shirtPreview/${thumbSide}/s-${shirtId}/sc-${shirtColorId}${urlDetails}`
        setImageUrl(imageUrl);
      } else {
        const imageUrl = `${API_BASE}/shirtPreview/${thumbSide}/s-${shirtId}/sc-${shirtColorId}`
        setImageUrl(imageUrl);
      }
    } else {
      setThumbData([]);
    }
  }, [activeThumb, horniNapis, obrazekPotisku, barvaPotisku, shirtId, potiskId, obrazekId, colorId, shirtColorId, textil, chosenSeznamJmen, listThumbChosen, ribbonsThumbChosen, basket]);

  useEffect(() => {
    getCodes();
  }, [getCodes, ribbonsThumbChosen]);

  // ad on click na smazani  a zmenu obrazku
  return (
    <React.Fragment>
      <div id="preview">
        <div className="smallThumbs clearfix"> </div>
        <div className="largeThumb">
          <ShirtPreview imageUrl={imageUrl} listThumbChosen={listThumbChosen} ribbonsThumbChosen={ribbonsThumbChosen} ribbonId={basket?.ribbons?.id} activeThumb={activeThumb} />
          {/* <img id="shirtPreview" src={imageUrl} alt="" onLoad={onShirtPreviewLoad} /> */}
        </div>
      </div>
      <div id="gallery" className="clearfix">
        {thumbData.map((oneTextilData) => (
          <ShirtThumb key={`gallery_item_${oneTextilData.textilId}_${oneTextilData.barvaId}`} oneTextil={oneTextilData}></ShirtThumb>
        ))}
        {listsExist && <ListsThumb key={`lists_item_thumb`} />}
        {ribbonsExist && <RibbonsThumb key={`ribbons_item_thumb`} id={basket?.ribbons?.id} />}
      </div>
      <div id="info">
        {(!listThumbChosen || !ribbonsThumbChosen) && <ShirtThumbDetail key={`ShirtThumbDetail_${shirtId}_${shirtColorId}`} />}
        {listsExist && listThumbChosen && <ListsThumbDetail key={`lists_item_thumb`} basketPrice={basketPrice} />}
        {ribbonsExist && ribbonsThumbChosen && <RibbonsThumbDetail key={`ribbons_item_thumb`} basketPrice={basketPrice} />}
      </div>
    </React.Fragment>
  );
};

const Side = (props) => {
  return (
    <React.Fragment>
      <SideDetail key="sideDetail" props={props} />
    </React.Fragment>
  );
};
export default Side;
