import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import genericService from "../../services/generic.service";

const SummaryTable = (props) => {
  const basket = useSelector((state) => state.cart.basket);
  const data = useSelector((state) => state.cart.data);
  const [basketPrice, setBasketPrice] = useState();
  const dispatch = useDispatch();

  const getBasketPrice = async () => {
    const basketPrice = await genericService.getBasketPrice(basket, data);

    if (typeof basket !== "undefined") {
      dispatch(cartActions.setPriplatky(basketPrice.priplatky));
    }

    setBasketPrice(basketPrice);
  };

  useEffect(() => {
    getBasketPrice();
  }, [basket?.items, basket?.predniPotisk, basket?.zadniPotisk, basket?.students?.mappedNames, basket?.ribbons, basket?.lists, basket?.sleeves?.leftSleeveNickNames, basket?.sleeves?.rightSleeveNickNames]);

  return (
    <React.Fragment>
      <table id="basket">
        <tbody>
          {basketPrice?.totalQuantity > 0 && <tr>
            <th>Triček</th>
            <td className="ks">{basketPrice?.totalQuantity} ks</td>
          </tr>}
          {basketPrice?.lists?.listsExist && (
            <tr>
              <th>Listin</th>
              <td className="ks">{basketPrice?.lists.listsQuantity} ks</td>
            </tr>
          )}
          {basketPrice?.ribbons?.ribbonsExists && (
            <tr>
              <th>Šerp</th>
              <td className="ks">{basketPrice?.ribbons.ribbonsQuantity} ks</td>
            </tr>
          )}
          <tr>
            <th>Celkem</th>
            <td>
              {new Intl.NumberFormat("cs-CS", {
                style: "currency",
                currency: "CZK",
                minimumFractionDigits: 0,
              }).format(basketPrice?.totalPrice)}
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

const BasketSummary = (props) => {
  return <React.Fragment>{ReactDOM.createPortal(<SummaryTable props={props} />, document.getElementById("basketPlaceholder"))}</React.Fragment>;
};

export default BasketSummary;
