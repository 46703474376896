import React, { useEffect, useState } from "react";
import { cartActions } from "../../store/cart-slice";
import { useDispatch, useSelector } from "react-redux";
import StepFiveSide from "./StepFiveSide";
import StepFiveShirtPreview from "./StepFiveShirtPreview";
import StepFiveShirtSummary from "./StepFiveShirtSummary";
import StepFiveListsSummary from "./StepFiveListsSummary";
import StepFiveRibbonsSummary from "./StepFiveRibbonsSummary";
import genericService from "../../services/generic.service";
import { useNavigate } from "react-router-dom";
import orderService from "../../services/order.service";

const StepFive = () => {
  const [isValidStep, setIsValidStep] = useState(false);
  const [shirtsForPreview, setShirtsForPreview] = useState([]);
  const dispatch = useDispatch();
  const basket = useSelector((state) => state?.cart?.basket);
  const activeStep = useSelector((state) => state?.cart?.activeStep);
  const data = useSelector((state) => state?.cart?.data);
  const cart = useSelector((state) => state?.cart);
  const items = useSelector((state) => state?.cart?.basket?.items);
  const itemPriceDetail = useSelector((state) => state?.cart?.itemPriceDetail);

  const listsExist = basket?.lists?.chosen;
  const ribbonsExist = basket?.ribbons?.chosen;
  const basketPrice = genericService.getBasketPrice(basket, data);

  let history = useNavigate();

  const setActivePage = () => {
    dispatch(
      cartActions.setActiveStep({
        step: "FIVE",
      })
    );
  };

  const previousStepNotComplete = () => {
    let isValid = genericService.validateStepFive(basket);
    setIsValidStep(isValid);
    return isValid;
  };

  const checkContact = () => {
    let isValid = true;

    isValid = isValid && Object.keys(basket?.contact).length > 0;

    Object.values(basket?.contact).map((value) => {
      isValid = isValid && value?.length > 0;
    });

    isValid = isValid && Object.keys(basket?.address).length > 0;
    Object.values(basket?.address).map((value) => {
      isValid = isValid && value?.length > 0;
    });
    return isValid;
  };

  const checkEmail = () => {
    let isValid = false;
    if (basket?.contact?.email != undefined) {
      // eslint-disable-next-line no-useless-escape
      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      isValid = isValid || regex.test(basket.contact.email);
    }
    return isValid;
  };

  const validatePhoneNumber = () => {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{3})$/;
    return re.test(basket?.contact?.phone);
  }


  const onFinishOrderClickHandler = async (event) => {
    if (!checkContact()) {
      dispatch(
        cartActions.setWarning({
          message: "Je třeba vyplnit všechny informace o kontaktní osobě.",
        })
      );
      return;
    }
    if (!checkEmail()) {
      dispatch(
        cartActions.setWarning({
          message: "Neplatný formát emailu.",
        })
      );
      return;
    }

    if (!validatePhoneNumber()) {
      dispatch(
        cartActions.setWarning({
          message: "Neplatný formát telefonního čísla.",
        })
      );
      return;
    }



    dispatch(cartActions.submitOrder({}));

    const prices = genericService.getBasketPrice(cart.basket, cart.data)

    const orderStatus = await orderService.sendOrder(cart.basket, prices)
    if (orderStatus?.message === 'OK') {
      // if (cart.basket.contact.email !== 'jaroslav.dub@gmail.com') {
      dispatch(cartActions.resetStore({}));
      // }
      event.preventDefault();
      window.location.href = "/stepSix";
    }
    else {

    }
  };

  const groupShirts = () => {
    var helper = {};
    var result = items.reduce(function (r, o) {
      var key = o.textil + "-" + o.colorId;

      if (!helper[key]) {
        helper[key] = Object.assign({}, o); // create a copy of o
        r.push(helper[key]);
      } else {
        helper[key].used += o.used;
        helper[key].instances += o.instances;
      }

      return r;
    }, []);

    setShirtsForPreview(result);
  };
 
  useEffect(() => {

    setActivePage();
    const isRibbonsValid = genericService.validateRibbons(basket)

    if (!isRibbonsValid) {
      history("/stepRibbons");
      return;
    }
    const isPreviousStepComplete = previousStepNotComplete()

    if (!isPreviousStepComplete) {
      history("/stepFour");
      return;
    }
    groupShirts();
  }, [genericService]);

  return (
    isValidStep && (
      <React.Fragment>
        <div id="largeGallery" className="clearfix">
          {shirtsForPreview?.map((oneItem) => (
            <StepFiveShirtPreview basket={basket} textil={oneItem} key={`shirtPreview#${oneItem.id}`} />
          ))}
        </div>
        <div id="main">
          <h2 className="strip n5">Souhrn</h2>
          {items?.map((oneItem) => (
            <StepFiveShirtSummary basket={basket} textil={oneItem} key={`shirtPreview#${oneItem.id}`} activeStep={activeStep} data={data} />
          ))}
          {listsExist && <StepFiveListsSummary basketPrice={basketPrice} basket={basket} />}
          {ribbonsExist && <StepFiveRibbonsSummary basketPrice={basketPrice} basket={basket} />}
          <div className="align-right">
            <button type="button" className="button gray send_order" onClick={onFinishOrderClickHandler}>
              Dokončit objednávku
            </button>
          </div>
        </div>
        <aside id="aside">
          <StepFiveSide />
        </aside>
        <div style={{ display: "flex", gap: "1em" }}>
        </div>
      </React.Fragment>
    )
  );
};

export default StepFive;
