import React, { useCallback } from 'react';
import { cartActions } from "../../store/cart-slice";
import { useDispatch, useSelector } from "react-redux";

const TextilColorItem = (props) => {

  const basket = useSelector((state) => state.cart.basket);

  const getId = (props) => {
    return props.textilId + "#" + props.colorId + "#" + props.sizeId;
  };
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => (state.cart.basket !== undefined ? state.cart.basket.items : []));
  let quantity = "";
  if (typeof cartItems === undefined || cartItems.length === undefined) {
    quantity = "";
  } else {
    const searchResult = cartItems.find((item) => item.id === getId(props));
    if (searchResult !== undefined) {
      quantity = searchResult.quantity;
    } else {
      quantity = "";
    }
  }

  const isExcluded = props.excludedTextil.find(obj => { return obj === props.colorId + "-" + props.sizeId; }) != undefined ? false : true;

  const bothStyle = { clear: "both" };
  const blockDisplay = { display: "block" };

  const quantityChangeHandler = useCallback(async (event) => {

    let uniqueItems = new Set()
    basket?.items?.forEach(item => {
      uniqueItems.add(item.textil+"#"+item.colorId)
    });
    // const itemsCount = basket?.items?.length != undefined ? basket?.items?.length : 0;
    const itemsCount = uniqueItems.size  
    const id = event.target.id;
    const itemInBasket = basket?.items?.filter((element) => element.id === id)?.length
    if (itemsCount < 4 || itemInBasket === 1) {
      const title = props.nazev;
      const price = props.cena;
      const quantity = event.target.value;
      const size = props.sizeName;
      const sizeId = props.sizeId;
      const material = props.material;
      const gramaz = props.gramaz;
      const colorId = props.colorId;
      const colorName = props.colorName;
      dispatch(
        cartActions.addItemToCart({
          id,
          title,
          price,
          quantity,
          size,
          sizeId,
          material,
          gramaz,
          colorId,
          colorName,
        })
      );
    } else {
      dispatch(
        cartActions.setWarning({
          message: "Maximální počet různobarevných produktů je 4.",
        })
      );
    }
  });

  return (
    <React.Fragment>
      <td>
        {isExcluded && <React.Fragment>
          <span className="size" style={blockDisplay}>
            {props.sizeName}
          </span>
          <div style={bothStyle}></div>
          <input type="text" id={getId(props)} name={getId(props)} value={quantity} className="text count product_size_input" onChange={quantityChangeHandler} />
        </React.Fragment>}
      </td>
    </React.Fragment>
  );
};

const TextilColors = (props) => {
  const border = "255,255,255";
  const blockDisplay = { display: "block" };

  return (
    <React.Fragment>
      <div className="addForm" id={`product_list_item_${props.textilId}`} style={blockDisplay}>
        <fieldset>
          <table>
            <tbody>
              {props.color.map((oneColor) => (
                <tr key={props.textilId + "#" + oneColor.id}>
                  <th>
                    <span className="color" style={{ background: "rgb(" + oneColor.rgb + ")", borderColor: "rgb(" + border + ")" }}></span>
                    {oneColor.nazev}
                  </th>
                  {props.size.map((oneSize) => (
                    <TextilColorItem
                      key={props.textilId + "#" + oneColor.id + "#" + oneSize.id}
                      sizeId={oneSize.id}
                      sizeName={oneSize.nazev}
                      colorId={oneColor.id}
                      textilId={props.textilId}
                      nazev={props.textil.nazev}
                      cena={props.textil.prodejni_cena}
                      material={props.textil.material}
                      gramaz={props.textil.gramaz}
                      colorName={oneColor.nazev}
                      excludedTextil={props.excludedTextil}
                    />
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </fieldset>
      </div>
    </React.Fragment>
  );
};

const MemoizedTextilColors = React.memo(TextilColors);

export default MemoizedTextilColors;
